<template>
  <div>
    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row>
            <!-- Per Page -->
            <b-col cols="12" md="7" class="d-flex align-items-center justify-content-start mb-1 mb-md-0"> </b-col>

            <!-- Search -->
            <b-col cols="12" md="5">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="filter" :clearable="true" class="d-inline-block mr-1" placeholder="Search..." />
                <add-role ref="add-role" :getRoles="getRoles" :loading="loading"></add-role>
                <b-button variant="primary" @click="addRole()" v-if="$Can('role_create')">
                  <span class="text-nowrap">Add New Role</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          v-if="$Can('role_access')"
          striped
          hover
          class="position-relative"
          :items="roles"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          responsive
          :small="true"
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          empty-text="No matching records found"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <!-- Column: Actions -->
          <template #cell(actions)="row" v-if="$Can('role_edit')">
            <b-button v-if="row.item.name != 'supermanager'" size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">
              Edit Permissions
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table>
        <b-modal :id="infoModal.id" :title="infoModal.title" @hidden="resetInfoModal" scrollable size="lg" ok-title="Save" @ok="editPerms(role)">
          {{ /*infoModal.content.permissions*/ }}
          <b-container>
            <b-form>
              <b-row class="mt-2">
                <b-col cols="12" xl="12" md="12" style="margin-bottom: 6rem;">
                  <b-tabs vertical nav-wrapper-class="nav-vertical" class="tab-class">
                    <b-tab active title="Inventory">
                      <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                        <b-table hover responsive small fixed :items="p_inventory" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                          <template #cell(description)="data">
                            <strong>{{ data.item.description }}</strong>
                          </template>

                          <template #cell()="data">
                            <b-form-checkbox switch size="sm" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                          </template>
                        </b-table>
                      </b-col>
                    </b-tab>
                    <b-tab title="Quotes Purchase">
                      <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                        <b-table hover responsive small fixed :items="p_purchase" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                          <template #cell(description)="data">
                            <strong>{{ data.item.description }}</strong>
                          </template>

                          <template #cell()="data">
                            <b-form-checkbox switch size="sm" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                          </template>
                        </b-table>
                      </b-col>
                    </b-tab>
                    <b-tab title="Quotes Sale">
                      <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                        <b-table hover responsive small fixed :items="p_sale" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                          <template #cell(description)="data">
                            <strong>{{ data.item.description }}</strong>
                          </template>

                          <template #cell()="data">
                            <b-form-checkbox switch size="sm" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                          </template>
                        </b-table>
                      </b-col>
                    </b-tab>

                    <b-tab title="Customer">
                      <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                        <b-table hover responsive small fixed :items="p_customer" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                          <template #cell(description)="data">
                            <strong>{{ data.item.description }}</strong>
                          </template>

                          <template #cell()="data">
                            <b-form-checkbox switch size="sm" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                          </template>
                        </b-table>
                      </b-col>
                    </b-tab>

                    <b-tab title="User">
                      <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                        <b-table hover responsive small fixed :items="p_user" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                          <template #cell(description)="data">
                            <strong>{{ data.item.description }}</strong>
                          </template>

                          <template #cell()="data">
                            <b-form-checkbox switch size="sm" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                          </template>
                        </b-table>
                      </b-col>
                    </b-tab>

                    <b-tab title="Roles/Other">
                      <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                        <b-table hover responsive small fixed :items="p_role" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                          <template #cell(description)="data">
                            <strong>{{ data.item.description }}</strong>
                          </template>

                          <template #cell()="data">
                            <b-form-checkbox switch size="sm" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                          </template>
                        </b-table>
                      </b-col>
                    </b-tab>

                    <b-tab title="Leads">
                      <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                        <b-table hover responsive small fixed :items="p_lead" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                          <template #cell(description)="data">
                            <strong>{{ data.item.description }}</strong>
                          </template>

                          <template #cell()="data">
                            <b-form-checkbox switch size="sm" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                          </template>
                        </b-table>
                      </b-col>
                    </b-tab>

                    <b-tab title="Accounting">
                      <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                        <b-table hover responsive small fixed :items="p_accounting" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                          <template #cell(description)="data">
                            <strong>{{ data.item.description }}</strong>
                          </template>

                          <template #cell()="data">
                            <b-form-checkbox switch size="sm" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                          </template>
                        </b-table>
                      </b-col>
                    </b-tab>

                    <b-tab title="Order">
                      <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                        <b-table hover responsive small fixed :items="p_order" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                          <template #cell(description)="data">
                            <strong>{{ data.item.description }}</strong>
                          </template>

                          <template #cell()="data">
                            <b-form-checkbox switch size="sm" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                          </template>
                        </b-table>
                      </b-col>
                    </b-tab>

                    <b-tab title="Inception">
                      <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                        <b-table hover responsive small fixed :items="p_inception" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                          <template #cell(description)="data">
                            <strong>{{ data.item.description }}</strong>
                          </template>

                          <template #cell()="data">
                            <b-form-checkbox switch size="sm" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                          </template>
                        </b-table>
                      </b-col>
                    </b-tab>

                    <b-tab title="Offroad">
                      <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                        <b-table hover responsive small fixed :items="p_offroad" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                          <template #cell(description)="data">
                            <strong>{{ data.item.description }}</strong>
                          </template>

                          <template #cell()="data">
                            <b-form-checkbox switch size="sm" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                          </template>
                        </b-table>
                      </b-col>
                    </b-tab>

                    <b-tab title="Home Statistic">
                      <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                        <b-table hover responsive small fixed :items="p_statistic" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                          <template #cell(description)="data">
                            <strong>{{ data.item.description }}</strong>
                          </template>

                          <template #cell()="data">
                            <b-form-checkbox switch size="sm" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                          </template>
                        </b-table>
                      </b-col>
                    </b-tab>
                  </b-tabs>
                </b-col>
              </b-row>
            </b-form>
          </b-container>
          {{/*rolePerms*/}}
        </b-modal>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="9" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios';
import { BFormGroup, BTable, BRow, BCol, BFormSelect, BPagination } from 'bootstrap-vue';
import store from '@/store';
import settingsStoreModule from '../../settingsStoreModule';
import { onUnmounted } from '@vue/composition-api';
import AddRole from './AddRole.vue';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BTabs, BTab, BCardText } from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    AddRole,
    ToastificationContent,
    BCardText,
    BTabs,
    BTab,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';

    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    const toast = useToast();
  },

  data() {
    // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },
    const tableColumns = [
      {
        key: 'id',
        show: true,
        sortable: true,
        class: 'text-center',
        thStyle: 'width: 10%',
      },
      {
        key: 'name',
        label: 'Role Name',
        show: true,
        sortable: true,
        class: 'text-center',
        thStyle: 'width: 30%',
      },
      {
        key: 'description',
        label: 'Role Description',
        show: true,
        sortable: true,
        class: 'text-center',
        thStyle: 'width: 30%',
      },
      {
        key: 'actions',
        show: true,
        sortable: false,
        class: 'text-center',
        thStyle: 'width: 30%',
      },
    ];

    return {
      rolePerms: [],
      edit: null,
      newitem: '',
      filter: null,
      filterOn: ['name'],
      perPage: 10,
      roles: [],
      permissions: [],
      currentPage: 1,
      perPageOptions: [5, 10, 15, 20],
      tableColumns,
      totalRows: 0,
      loading: false,
      p_inventory: [],
      p_purchase: [],
      p_sale: [],
      p_customer: [],
      p_user: [],
      p_role: [],
      p_lead: [],
      p_accounting: [],
      p_order: [],

      p_inception: [],
      p_offroad: [],

      p_statistic: [],
      fields: ['Description', '-'],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    };
  },
  computed: {
    dataMeta() {
      return {
        // from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        // to: perPage.value * (currentPage.value - 1) + localItemsCount,
        // of: totalUsers.value,
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  methods: {
    addRole() {
      this.$root.$emit('bv::show::modal', 'addNewRole');
    },
    onEdit(id, value) {
      this.edit = this.edit !== id ? id : this.editItem(id, value);
    },
    getRoles() {
      this.loading = true;
      store
        .dispatch('settings/fetchRoles', [])
        .then((res) => {
          this.totalRows = res.data.data.length;
          this.roles = res.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPermissions() {
      this.loading = true;
      store
        .dispatch('settings/fetchPermissions', [])
        .then((res) => {
          this.loading = false;
          this.permissions = res.data.data;
          res.data.data.forEach((p) => {
            p.group === 'inventory' ? this.p_inventory.push(p) : '';
            p.group === 'purchase' ? this.p_purchase.push(p) : '';
            p.group === 'sale' ? this.p_sale.push(p) : '';
            p.group === 'customer' ? this.p_customer.push(p) : '';
            p.group === 'user' ? this.p_user.push(p) : '';
            p.group === 'role' ? this.p_role.push(p) : '';
            p.group === 'other' ? this.p_role.push(p) : '';
            p.group === 'lead' ? this.p_lead.push(p) : '';
            p.group === 'accounting' ? this.p_accounting.push(p) : '';
            p.group === 'order' ? this.p_order.push(p) : '';
            p.group === 'statistic' ? this.p_statistic.push(p) : '';

            p.group === 'inception' ? this.p_inception.push(p) : '';
            p.group === 'offroad' ? this.p_offroad.push(p) : '';
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    removeItem(event, id) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          event.preventDefault();
          axiosIns
            .delete(`body/${id}`)
            .then((res) => {
              if (res.status == 200) {
                this.getRoles();
              }
            })
            .catch((error) => {
              console.log('error delete ', error);
            });
        }
      });
    },
    editItem(id, value) {
      if (value == '') {
        return;
      }
      axiosIns
        .put(`body/${id}`, {
          value: value,
        })
        .then((res) => {
          if (res.status == 200) {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editPerms(role) {
      this.loading = true;
      axiosIns
        .put(`roles/${role}`, {
          permissions: JSON.parse(JSON.stringify(this.rolePerms)),
        })
        .then((response) => {
          if (response.status == 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Permissions Updated Successfully',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
            this.getRoles();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: 'Permissions Updated Failed',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
          }
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Edit Permissions For : ${item.name}`;
      this.infoModal.content = item;
      this.rolePerms = item.permissions;
      this.role = item.name;
      this.$root.$emit('bv::show::modal', this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = '';
      this.infoModal.content = '';
    },
  },
  created() {
    this.getRoles();
    this.getPermissions();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
.modal-body {
  overflow-x: hidden;
  padding: 0;
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
