<template>
  <b-modal id="addNewRole" title="Add New Role" @hidden="resetInfoModal" scrollable ok-title="Save" @ok="submitForm" size="lg">
    <b-container style="margin-top: 1rem;">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="3">
              <b-form-group class="input-group-merge" :class="validation ? 'is-valid' : 'is-invalid'" label="Role Name:">
                <validation-provider #default="{ errors }" name="Role Name" rules="required">
                  <b-form-input v-model="role.name" list="input-list" style="mr-1" placeholder="Enter Role Name" :state="validation" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="9" style="padding-left: 0px!important">
              <b-form-group class="input-group-merge" :class="validation ? 'is-valid' : 'is-invalid'" label="Role Description:">
                <validation-provider #default="{ errors }" name="Role Description:" rules="required">
                  <b-form-input v-model="role.description" list="input-list" placeholder="Enter Role Description" :state="validationdes" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" xl="12" md="12" style="margin-bottom: 6rem;">
              <b-tabs vertical nav-wrapper-class="nav-vertical" class="tab-class">
                <b-tab active title="Inventory">
                  <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                    <b-table hover responsive small fixed :items="p_inventory" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                      <template #cell(description)="data">
                        <strong>{{ data.item.description }}</strong>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-col>
                </b-tab>
                <b-tab title="Quotes Purchase">
                  <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                    <b-table hover responsive small fixed :items="p_purchase" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                      <template #cell(description)="data">
                        <strong>{{ data.item.description }}</strong>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-col>
                </b-tab>
                <b-tab title="Quotes Sale">
                  <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                    <b-table hover responsive small fixed :items="p_sale" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                      <template #cell(description)="data">
                        <strong>{{ data.item.description }}</strong>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-col>
                </b-tab>

                <b-tab title="Customer">
                  <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                    <b-table hover responsive small fixed :items="p_customer" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                      <template #cell(description)="data">
                        <strong>{{ data.item.description }}</strong>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-col>
                </b-tab>

                <b-tab title="User">
                  <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                    <b-table hover responsive small fixed :items="p_user" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                      <template #cell(description)="data">
                        <strong>{{ data.item.description }}</strong>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-col>
                </b-tab>

                <b-tab title="Roles/Other">
                  <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                    <b-table hover responsive small fixed :items="p_role" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                      <template #cell(description)="data">
                        <strong>{{ data.item.description }}</strong>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-col>
                </b-tab>

                <b-tab title="Leads">
                  <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                    <b-table hover responsive small fixed :items="p_lead" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                      <template #cell(description)="data">
                        <strong>{{ data.item.description }}</strong>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-col>
                </b-tab>

                <b-tab title="Accounting">
                  <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                    <b-table hover responsive small fixed :items="p_accounting" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                      <template #cell(description)="data">
                        <strong>{{ data.item.description }}</strong>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-col>
                </b-tab>

                <b-tab title="Order">
                  <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                    <b-table hover responsive small fixed :items="p_order" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                      <template #cell(description)="data">
                        <strong>{{ data.item.description }}</strong>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-col>
                </b-tab>

                <b-tab title="Inception">
                  <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                    <b-table hover responsive small fixed :items="p_inception" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                      <template #cell(description)="data">
                        <strong>{{ data.item.description }}</strong>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-col>
                </b-tab>

                <b-tab title="Offroad">
                  <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                    <b-table hover responsive small fixed :items="p_offroad" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                      <template #cell(description)="data">
                        <strong>{{ data.item.description }}</strong>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-col>
                </b-tab>

                <b-tab title="Home Statistic">
                  <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;">
                    <b-table hover responsive small fixed :items="p_statistic" class="position-relative" :fields="fields" style="padding-left: 1rem;">
                      <template #cell(description)="data">
                        <strong>{{ data.item.description }}</strong>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-col>
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
          {{/*selected*/}}
        </b-form>
      </validation-observer>
    </b-container>
  </b-modal>
</template>

<script>
import store from '@/store';
import Ripple from 'vue-ripple-directive';
import settingsStoreModule from '../../settingsStoreModule';
import { onUnmounted } from '@vue/composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import { BTabs, BTab, BCardText } from 'bootstrap-vue';
import { BTable } from 'bootstrap-vue';
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BTabs,
    BTab,
    BTable,
  },
  directives: {
    Ripple,
  },
  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';

    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },
  props: ['getRoles', 'loading'],
  data() {
    return {
      fields: ['Description', '-'],
      required,
      selected: [],
      permissions: [],
      p_inventory: [],
      p_purchase: [],
      p_sale: [],
      p_customer: [],
      p_user: [],
      p_role: [],
      p_lead: [],
      p_accounting: [],
      p_order: [],
      p_statistic: [],
      p_inception: [],
      p_offroad: [],
      role: {
        name: '',
        description: '',
        permissions: '',
      },
      submitted: false,
    };
  },
  methods: {
    submitForm() {
      const self = this;
      this.$emit('loading', true);
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.submitted = true;
          this.$bvModal.hide('addNewRole');
          this.role.permissions = this.selected;
          store.dispatch('settings/addRole', self.role).then((response) => {
            if (response.status == 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Role Add Successfully',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              this.getRoles();
              this.resetInfoModal();
            }
          }); // dispatch store action
        }
      });
    },
    getPermissions() {
      store
        .dispatch('settings/fetchPermissions', [])
        .then((res) => {
          this.permissions = res.data.data;
          res.data.data.forEach((p) => {
            p.group === 'inventory' ? this.p_inventory.push(p) : '';
            p.group === 'purchase' ? this.p_purchase.push(p) : '';
            p.group === 'sale' ? this.p_sale.push(p) : '';
            p.group === 'customer' ? this.p_customer.push(p) : '';
            p.group === 'user' ? this.p_user.push(p) : '';
            p.group === 'role' ? this.p_role.push(p) : '';
            p.group === 'other' ? this.p_role.push(p) : '';
            p.group === 'lead' ? this.p_lead.push(p) : '';
            p.group === 'accounting' ? this.p_accounting.push(p) : '';
            p.group === 'order' ? this.p_order.push(p) : '';
            p.group === 'statistic' ? this.p_statistic.push(p) : '';

            p.group === 'inception' ? this.p_inception.push(p) : '';
            p.group === 'offroad' ? this.p_offroad.push(p) : '';
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetInfoModal() {
      this.submitted = false;
      this.role.name = '';
      this.role.description = '';
      this.role.permissions = '';
      this.selected = [];
    },
    handleSelected(tr) {
      // this.$vs.notify({
      //   title: `Selected ${tr.username}`,
      //   text: `Email: ${tr.email}`
      // })
    },
  },
  computed: {
    validation() {
      return this.role.name.length > 1;
    },
    validationdes() {
      return this.role.description.length > 1;
    },
  },
  created() {
    this.getPermissions();
  },
};
</script>

<style scoped>
.tab-class >>> .nav-item {
  position: relative;
  display: flex;
  justify-content: start;
}

.tab-class >>> .nav-vertical {
  padding-left: 0 !important;
}
</style>
